export enum routes {
  START = '/',
  CONTACT = '/kontakt',
  PT = '/pt',
  YOGA = '/yoga',
  COMPANY = '/foretag',
  ABOUT = '/om',
  PHYSIO = '/fysioterapi',
  BOOKING = '/boka',
};
