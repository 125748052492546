import { Helmet } from "../../components/helmet/Helmet";
import { Page } from "../../components/page/Page";
import { usePageText } from "../../hooks/usePageText";
import { DisplayPageText } from "../../components/DisplayPageText/DisplayPageText";
import { useEffect } from "react";

const OG_TITLE = 'Boka!';
const TITLE = `Movety - ${OG_TITLE}`;
const DESCRIPTION = 'Boka behandling';
const meta = [
  {name: 'description', content: DESCRIPTION},
];
const metaPrio = [
  {name:'og:title', content: OG_TITLE},
  {name: 'og:description', content: DESCRIPTION},
];

export function BOOKING() {
  const {data: pageText} = usePageText('BOOKING');

  useEffect(() => {
    const script = document.createElement('script');

    script.src = "https://system.easypractice.net/js/templates/onlinebook_iframe.js";
    // script.async = true;

    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    }
  }, []);

  return (
    <Page className="booking">
      <Helmet meta={meta} metaPrio={metaPrio} title={TITLE} />
      {pageText.map((t, idx) => <DisplayPageText key={idx} pageText={t} />)}
      <iframe
        frameBorder={0}
        style={{width: "100%", height: "1000px"}}
        src="https://system.easypractice.net/book/movety"
      >
      </iframe>
    </Page>
  )
}