import { Helmet } from "../../components/helmet/Helmet";
import { Page } from "../../components/page/Page";
import { usePageText } from "../../hooks/usePageText";
import { DisplayPageText } from "../../components/DisplayPageText/DisplayPageText";

const OG_TITLE = 'Fysioterapi!';
const TITLE = `Movety - ${OG_TITLE}`;
const DESCRIPTION = 'Vi erbjuder fysioterapi';
const meta = [
  {name: 'description', content: DESCRIPTION},
];
const metaPrio = [
  {name:'og:title', content: OG_TITLE},
  {name: 'og:description', content: DESCRIPTION},
];

export function Physio() {
  const {data: pageText} = usePageText('PHYSIO');

  return (
    <Page className="physio">
      <Helmet meta={meta} metaPrio={metaPrio} title={TITLE} />
      {pageText.map((t, idx) => <DisplayPageText key={idx} pageText={t} />)}
    </Page>
  )
}